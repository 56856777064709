var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"o-company-documents"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('layout.misc.company.documents.title')))])],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('p',[_vm._v(_vm._s(_vm.$t('layout.misc.company.documents.list.title')))]),(_vm.state.documents && _vm.state.documents.length)?_c('table',{staticStyle:{"min-width":"100%"}},[_c('thead',[_c('tr',[_c('th',{staticStyle:{"width":"50px"}}),_c('th',[_vm._v(_vm._s(_vm.$t('layout.misc.title')))]),_c('th',{staticStyle:{"width":"150px"}})])]),_c('tbody',_vm._l((_vm.state.documents),function(file,$index){return _c('tr',{key:file.id},[_c('td',{staticStyle:{"text-align":"center"}},[_vm._v(_vm._s($index + 1))]),_c('td',[_vm._v(_vm._s(file.name))]),_c('td',{staticStyle:{"text-align":"center"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();return _vm.getFile(file.name)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-download-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('layout.misc.download')))])]),_c('v-dialog',{attrs:{"max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"red","dark":"","depressed":"","icon":""}},Object.assign({}, tooltip, dialog)),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('layout.misc.delete')))])])]}}],null,true),model:{value:(file.removeDialog),callback:function ($$v) {_vm.$set(file, "removeDialog", $$v)},expression:"file.removeDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('layout.misc.areYouSure')))]),_c('v-card-actions',{staticClass:"d-flex justify-space-between"},[_c('v-btn',{attrs:{"color":"red","depressed":"","dark":""},on:{"click":function($event){return _vm.deleteFile(file)}}},[_vm._v(" "+_vm._s(_vm.$t('layout.misc.delete')))]),_c('v-btn',{attrs:{"depressed":""},on:{"click":function($event){file.removeDialog = false}}},[_vm._v(" "+_vm._s(_vm.$t('layout.misc.cancel')))])],1)],1)],1)],1)])}),0)]):[_c('p',[_vm._v(_vm._s(_vm.$t('layout.misc.company.documents.list.empty')))])]],2),_c('v-col',{attrs:{"cols":"12"}},[_c('v-divider')],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-file-input',{attrs:{"label":_vm.$t('layout.misc.company.documents.add.label'),"hint":_vm.$t('layout.misc.acceptedFiles'),"persistent-hint":""},model:{value:(_vm.model.document),callback:function ($$v) {_vm.$set(_vm.model, "document", $$v)},expression:"model.document"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"block":"","depressed":"","color":"primary"},on:{"click":_vm.onSubmit}},[_vm._v(_vm._s(_vm.$t('layout.misc.company.documents.add.title')))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }